import React from "react";

import { useTranslation } from "react-i18next";

import img1 from '../../img/Fees/man.png'
import img2 from '../../img/Fees/rec.png'

import Button from '../Button'

function Exceptional () {

    const { t } = useTranslation();
 
    return(
        <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
            <div className="flex flex-col-reverse xl:flex-col gap-[80px] xl:gap-[160px]">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px]">{t('fees.56')}</p>
                            <svg className="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="12" height="91" viewBox="0 0 12 91" fill="none">
                                <line y1="-0.952525" x2="80.9126" y2="-0.952525" transform="matrix(1.19249e-08 1 1 -1.19249e-08 6.94202 -0.00585938)" stroke="url(#paint0_linear_75_791)" stroke-width="1.90505"/>
                                <circle cx="5.94956" cy="5.94956" r="4.99704" transform="matrix(1 0 0 -1 0 90.9619)" fill="#0038FF" stroke="url(#paint1_linear_75_791)" stroke-width="1.90505"/>
                                <defs>
                                    <linearGradient id="paint0_linear_75_791" x1="80.9126" y1="-1.85653e-06" x2="0" y2="4.88109e-06" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#0038FF"/>
                                    <stop offset="1" stop-color="#0038FF" stop-opacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_75_791" x1="11.8991" y1="-2.20911e-05" x2="0" y2="-2.20789e-05" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#0038FF"/>
                                    <stop offset="1" stop-color="#0038FF" stop-opacity="0"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className="flex items-center mt-[20px]">
                            <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px]">{t('fees.57')}</p>
                            <svg className="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="12" height="91" viewBox="0 0 12 91" fill="none">
                                <line y1="-0.952525" x2="80.9126" y2="-0.952525" transform="matrix(1.19249e-08 1 1 -1.19249e-08 6.94202 -0.00585938)" stroke="url(#paint0_linear_75_791)" stroke-width="1.90505"/>
                                <circle cx="5.94956" cy="5.94956" r="4.99704" transform="matrix(1 0 0 -1 0 90.9619)" fill="#0038FF" stroke="url(#paint1_linear_75_791)" stroke-width="1.90505"/>
                                <defs>
                                    <linearGradient id="paint0_linear_75_791" x1="80.9126" y1="-1.85653e-06" x2="0" y2="4.88109e-06" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#0038FF"/>
                                    <stop offset="1" stop-color="#0038FF" stop-opacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_75_791" x1="11.8991" y1="-2.20911e-05" x2="0" y2="-2.20789e-05" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#0038FF"/>
                                    <stop offset="1" stop-color="#0038FF" stop-opacity="0"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <Button className="mt-[20px] xl:mt-[45px] mr-auto"/>
                    </div>
                    <div>
                        <img src={img1} className="xl:mt-[0px] mt-[20px]" alt="" />
                    </div>
                </div>
                <div className="flex flex-col-reverse xl:flex-row justify-between">
                    <div className="mt-[20px] xl:mt-0 flex flex-col">
                        <img src={img2} alt="" />
                        <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] mt-[20px] xl:mt-[45px]">{t('fees.58')}</p>
                        <Button className="mt-[20px] xl:mt-[45px] mr-auto"/>
                    </div>
                    <div>
                        <p className="bebas text-[24px] xl:text-[48px] xl:max-w-[488px] text-center xl:text-left leading-[1]">{t('fees.59')}</p>
                        <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] mt-[20px] xl:mt-[45px]">{t('fees.60')}</p>
                        <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] mt-[20px]">{t('fees.61')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exceptional;