import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import Fade from 'react-reveal/Fade'

import img from '../../img/Home/rec.png'

function Secure () {

    const { t } = useTranslation();
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]">    
            <p className="bebas text-[24px] xl:text-[48px]">{t('home.28')}</p>
            <div className="flex flex-col xl:flex-row-reverse justify-between mt-[20px] xl:mt-[45px]">
                <div className="xl:w-[488px] flex flex-col">
                    <p className="helvetica text-[14px] xl:text-[16px]">{t('home.29')}</p>
                    <Button className="mt-[45px] hidden xl:block mr-auto"/>  
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <Fade ledt><img src={img} alt="" className="mx-auto"/></Fade>
                </div>
            </div>
        </div>
    );
}

export default Secure;