import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function Faq() {
    const { t } = useTranslation();

    const faqData = [
        {
            question: 'customer.21',
            answer: 'customer.22'
        },
        {
            question: 'customer.23',
            answer: 'customer.24'
        },
        {
            question: 'customer.25',
            answer: 'customer.26'
        },
        {
            question: 'customer.27',
            answer: 'customer.28'
        },
        {
            question: 'customer.29',
            answer: 'customer.30'
        },
        {
            question: 'customer.31',
            answer: 'customer.32'
        },
        {
            question: 'customer.33',
            answer: 'customer.34'
        },
        {
            question: 'customer.35',
            answer: 'customer.36'
        },
        {
            question: 'customer.37',
            answer: 'customer.38'
        },
        {
            question: 'customer.39',
            answer: 'customer.40'
        },
    ];

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]">
            <p className="bebas text-[24px] xl:text-[48px] text-center xl:text-left leading-[1]">{t('customer.20')}</p>
            <div className="mt-[20px] xl:mt-[45px] rounded-[20px] border-[#0038FF] border overflow-hidden">
                {faqData.map((faq, index) => (
                    <div key={index}>
                        <div className={`flex justify-between transition-all duration-300 ${index !== faqData.length - 1 ? 'border-b border-[#0038FF]' : ''} ${openIndex === index ? 'h-auto bg-[#0038FF]' : 'bg-[#F3F2F2] h-[74px]'}`}>
                            <div className='mx-[20px] flex flex-col justify-center'>
                                <p className={`bebas text-[16px] xl:text-[20px] ${openIndex === index ? 'text-[#FFF] mt-[15px]' : 'text-[#000]'} max-w-[200px] sm:max-w-[1280px]`}>
                                    <span className="mr-[8px] bebas text-[20px] xl:text-[40px]">{String(index + 1).padStart(2, '0')}.</span>
                                    {t(faq.question)}
                                </p>
                                {openIndex === index && (
                                    <p className='text-[#FFF] roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] sm:max-w-[593px]'>{t(faq.answer)}</p>
                                )}
                            </div>
                            <div className={`mx-[25px] w-[30px] h-[30px] ${openIndex === index ? 'bg-white' : 'bg-[#0038FF]'} flex-shrink-0 rounded-[80px] flex cursor-pointer items-center justify-center mt-[20px]`} onClick={() => toggleText(index)}>
                                <svg className={`transition-all ${openIndex === index ? '' : '-rotate-90'}`} xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                                    <path d="M8.17566 9.58691L16.9501 16.6852" stroke={openIndex === index ? 'black' : 'white'} stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9.69702 16.6787L16.9495 16.6859L15.4277 9.59485" stroke={openIndex === index ? 'black' : 'white'} stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Faq;