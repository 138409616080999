import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'


function Avaliable () {

    const { t } = useTranslation();
      
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] px-[20px] py-[40px] bg-white border border-[#0038FF] rounded-[20px] xl:rounded-[30px] mt-[80px] xl:mt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <div>
                    <div className="flex items-center gap-[20px]">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.57639" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px]">{t('customer.12')}</p>
                    </div>
                    <div className="flex items-center mt-[20px] gap-[20px]">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.57639" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px]">{t('customer.13')}</p>
                    </div>
                    <div className="flex items-center mt-[20px] gap-[20px]">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.57639" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px]">{t('customer.14')}</p>
                    </div>
                    <div className="flex items-center mt-[20px] gap-[20px]">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.57639" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px]">{t('customer.15')}</p>
                    </div>
                    <div className="flex items-center mt-[20px] gap-[20px]">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.57639" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px]">{t('customer.16')}</p>
                    </div>
                    <div className="flex items-center mt-[20px] gap-[20px]">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.57639" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px]">{t('customer.17')}</p>
                    </div>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[20px] xl:mt-[40px] xl:max-w-[415px]">{t('customer.18')}</p>
                </div>
                <div className="flex flex-col">
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[20px] xl:mt-[40px] xl:max-w-[525px]">{t('customer.19')}</p>
                    <Button className="mt-[35px] xl:mt-[45px] mr-auto"/>
                </div>
            </div>
        </div>
    );
}

export default Avaliable;