import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import Fade from 'react-reveal/Fade'

import acc from '../../img/Home/acc.png'

function TradeAcc () {

    const { t } = useTranslation();

    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]">   
            <p className="bebas text-[24px] xl:text-[48px]">{t('home.34')}</p> 
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[45px]">
                <div className="xl:w-[488px] flex flex-col">
                    <p className="helvetica text-[14px] xl:text-[16px]">{t('home.35')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[15px]">{t('home.36')}</p>
                    <Button className="mt-[45px] mr-auto hidden xl:block"/>
                </div>
                <div className="mt-[20px] xl:mt-0 flex flex-col gap-[10px] relative z-50">
                    <div className="flex items-center bg-white rounded-[80px] border border-[#0038FF] xl:w-[384px] h-[53px]">
                        <svg className="ml-[20px]" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.60764" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px] mx-auto">{t('home.37')}</p>
                    </div>
                    <div className="flex items-center bg-white rounded-[80px] border border-[#0038FF] xl:w-[384px] h-[53px]">
                        <svg className="ml-[20px]" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.60764" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px] mx-auto">{t('home.38')}</p>
                    </div>
                    <div className="flex items-center bg-white rounded-[80px] border border-[#0038FF] xl:w-[384px] h-[53px]">
                        <svg className="ml-[20px]" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.60764" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px] mx-auto">{t('home.39')}</p>
                    </div>
                    <div className="flex items-center bg-white rounded-[80px] border border-[#0038FF] xl:w-[384px] h-[53px]">
                        <svg className="ml-[20px]" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.60764" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px] mx-auto">{t('home.40')}</p>
                    </div>
                    <div className="flex items-center bg-white rounded-[80px] border border-[#0038FF] xl:w-[384px] h-[53px]">
                        <svg className="ml-[20px]" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <circle cx="7.57639" cy="7.60764" r="7.57639" fill="#0038FF"/>
                        </svg>
                        <p className="helvetica text-[14px] xl:text-[16px] mx-auto">{t('home.41')}</p>
                    </div>
                </div>
                <Fade right>
                    <img src={acc} className="hidden xl:block absolute right-[-200px] top-[-50px] z-0" alt="" />
                </Fade>
            </div>
        </div>
    );
}

export default TradeAcc;