import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

function Transparent () {

    const { t } = useTranslation();
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]">   
            <p className="bebas text-[24px] xl:text-[48px]">{t('home.30')}</p> 
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[45px]">
                <div className="xl:w-[488px] flex flex-col">
                    <p className="helvetica text-[14px] xl:text-[16px]">{t('home.33')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[15px]">{t('home.32')}</p>
                </div>
                <div className="xl:w-[488px] mt-[15px] xl:mt-0 flex flex-col">
                    <p className="helvetica text-[14px] xl:text-[16px]">{t('home.31')}</p>
                    <Button className="mt-[45px] mr-auto"/>  
                </div>
            </div>
        </div>
    );
}

export default Transparent;