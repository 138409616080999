import React from "react";

import { useTranslation } from "react-i18next";

import m1 from '../../img/About/bars.png'
import m2 from '../../img/About/money.png'
import m3 from '../../img/About/portfolio.png'
import m4 from '../../img/About/planet.png'

import Button from '../Button'

function OurAchievements () {

    const { t } = useTranslation();
 
    return(
        <div className="relative flex flex-col max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="flex flex-col">
                    <p className="bebas text-[24px] xl:text-[48px]">{t('about.10')}</p>
                    <Button className="mt-[35px] xl:mt-[45px] mr-auto hidden xl:block"/>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px]">{t('about.11')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[15px] xl:max-w-[488px]">{t('about.12')}</p>
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-[40px] xl:gap-0 xl:gap-y-[100px] justify-items-center mt-[60px] xl:mt-[120px]">
                <div>
                    <img src={m1} alt="" />
                    <p className="bebas text-[20px] xl:text-[24px] mt-[25px]">{t('about.13')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[383px] mt-[20px]">{t('about.14')}</p>
                </div>
                <div>
                    <img src={m2} alt="" />
                    <p className="bebas text-[20px] xl:text-[24px] mt-[25px]">{t('about.15')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[383px] mt-[20px]">{t('about.16')}</p>
                </div>
                <div>
                    <img src={m3} alt="" />
                    <p className="bebas text-[20px] xl:text-[24px] mt-[25px]">{t('about.17')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[383px] mt-[20px]">{t('about.18')}</p>
                </div>
                <div>
                    <img src={m4} alt="" />
                    <p className="bebas text-[20px] xl:text-[24px] mt-[25px]">{t('about.19')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[383px] mt-[20px]">{t('about.20')}</p>
                </div>
            </div>
            <Button className="mt-[35px] xl:mt-[45px] mr-auto xl:hidden block"/>
        </div>
    );
}

export default OurAchievements;