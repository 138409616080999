import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import Fade from 'react-reveal/Fade'

function Bonuses () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    const Acc = [
        {
            name: 'fees.19',
            text1: 'fees.46',
            text2: 'fees.47',
        },
        {
            name: 'fees.20',
            text1: 'fees.48',
            text2: 'fees.49',
        },
        {
            name: 'fees.21',
            text1: 'fees.50',
            text2: 'fees.51',
        },
        {
            name: 'fees.22',
            text1: 'fees.52',
            text2: 'fees.53',
        },
        {
            name: 'fees.23',
            text1: 'fees.54',
            text2: 'fees.55',
        },
    ]
      
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px] flex flex-col xl:flex-row justify-between">
            <p className="text-[30px] xl:text-[48px] bebas xl:hidden block">{t('fees.44')}</p>
            <p className="text-[14px] xl:text-[16px] helvetica leading-[1.4] mt-[20px] xl:hidden block">{t('fees.45')}</p>
            <div className="xl:w-[383px] flex flex-wrap xl:flex-col gap-[10px] mt-[20px] xl:mt-0">
                {Acc.map((cc, index) => (
                    <div key={index} onClick={() => setActive(index)} className={`flex items-center justify-center px-[20px] flex-grow h-[53px] rounded-[80px] cursor-pointer transition-all border border-[#0038FF] ${active === index ? 'bg-[#0038FF]' : 'bg-white'}`}>
                        <p className={`bebas text-[20px] xl:text-[32px] transition-all ${active === index ? 'text-white' : 'text-black'}`}>{t(cc.name)}</p>
                    </div>
                ))}
            </div>
            <div className="mt-[20px] xl:mt-0 xl:max-w-[600px]">
                <p className="text-[30px] xl:text-[48px] bebas hidden xl:block">{t('fees.44')}</p>
                <p className="text-[14px] xl:text-[16px] helvetica leading-[1.4] mt-[20px] hidden xl:block">{t('fees.45')}</p>
                <Fade key={active}><p className="text-[30px] xl:text-[48px] bebas mt-[20px] xl:mt-[45px]">{t(Acc[active].name)}</p>
                <div className="flex items-center gap-[20px] mt-[10px]">
                    <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <circle cx="7.57639" cy="7.57053" r="7.57639" fill="#0038FF"/>
                    </svg>
                    <p className="text-[14px] xl:text-[16px] helvetica">{t(Acc[active].text1)}</p>
                </div>
                <div className="flex items-center gap-[20px] mt-[10px]">
                    <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <circle cx="7.57639" cy="7.57053" r="7.57639" fill="#0038FF"/>
                    </svg>
                    <p className="text-[14px] xl:text-[16px] helvetica">{t(Acc[active].text2)}</p>
                </div></Fade>
            </div>
        </div>
    );
}

export default Bonuses;