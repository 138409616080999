import React, { useEffect } from "react";

import Main from '../components/Account/Main'
import Types from "../components/Account/Types";
import Belives from "../components/Account/Belives";
import WhatType from "../components/Account/WhatType";
import Join from "../components/Home/Join";

function Account () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F2F2F1] overflow-hidden">
            <Main/>
            <Types/>
            <Belives/>
            <WhatType/>
            <Join/>
        </div>
    );
}

export default Account;