import React, { useEffect } from "react";

import Main from '../components/Trading/Main'
import Sw from "../components/Trading/Sw";
import TradingTools from "../components/Trading/TradingTools";
import TradingBonuses from "../components/Trading/TradingBonuses";
import Join from "../components/Home/Join"

function Trading () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F2F2F1] overflow-hidden">
            <Main/>
            <Sw/>
            <TradingTools/>
            <TradingBonuses/>
            <Join/>
        </div>
    );
}

export default Trading;