import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import m1 from '../../img/About/m1.png'
import m2 from '../../img/About/m2.png'
import m3 from '../../img/About/m3.png'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px]">
            <div className="bg-white border flex flex-col-reverse xl:flex-row gap-[20px] xl:gap-[35px] relative overflow-hidden rounded-[20px] border-[#0038FF] mt-[20px] xl:mt-[60px]">
                <img src={m1} alt="" className="rounded-[19px] mx-auto"/>
                <div className="flex flex-col mx-[20px] xl:mx-0">
                    <p className="bebas text-[24px] xl:text-[48px] mt-[20px]">{t('about.1')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[20px] xl:mt-[45px] xl:mr-[25px]">{t('about.2')}</p>
                    <Button className="mt-[35px] xl:mt-[45px] mr-auto"/>
                </div>              
            </div>
            <div className="bg-white mt-[20px] xl:mt-[45px] border flex flex-col-reverse xl:flex-row-reverse gap-[20px] xl:gap-[35px] relative overflow-hidden rounded-[20px] border-[#0038FF]">
                <img src={m2} alt="" className="rounded-[19px] mx-auto"/>
                <div className="flex flex-col mx-[20px] xl:mx-0">
                    <p className="bebas text-[24px] xl:text-[48px] mt-[20px] xl:ml-[25px]">{t('about.3')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[20px] xl:mt-[45px] xl:ml-[25px]">{t('about.4')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[15px] xl:ml-[25px]">{t('about.5')}</p>
                </div>              
            </div>
            <div className="bg-white border mt-[20px] xl:mt-[45px] flex flex-col-reverse xl:flex-row gap-[20px] xl:gap-[35px] relative overflow-hidden rounded-[20px] border-[#0038FF]">
                <img src={m3} alt="" className="rounded-[19px] mx-auto"/>
                <div className="flex flex-col mx-[20px] xl:mx-0">
                    <p className="bebas text-[24px] xl:text-[48px] mt-[20px]">{t('about.6')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[20px] xl:mt-[45px] xl:mr-[25px]">{t('about.7')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[15px] xl:mr-[25px]">{t('about.8')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[15px] xl:mr-[25px]">{t('about.9')}</p>
                </div>              
            </div>
        </div>
    );
}

export default Main;