import React, { useEffect } from "react";

import Main from '../components/About/Main'
import Join from "../components/Home/Join";
import OurAchievements from "../components/About/OurAchievements";

function About () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F2F2F1] overflow-hidden">
            <Main/>
            <OurAchievements/>
            <Join/>
        </div>
    );
}

export default About;