import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import sw from '../../img/Trading/sw.png'
import sw1 from '../../img/Trading/sw/bank.png'
import sw2 from '../../img/Trading/sw/bill-receipt.png'
import sw3 from '../../img/Trading/sw/coins.png'
import sw4 from '../../img/Trading/sw/credit-card-back.png'
import sw5 from '../../img/Trading/sw/transaction19.png'

import btn from '../../img/Home/arrow.png'

import Fade from 'react-reveal/Fade'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

function Sw () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    const data = [
        {
            title: 'trading.5',
            text: 'trading.6',
            img: sw1
        },
        {
            title: 'trading.7',
            text: 'trading.8',
            img: sw3
        },
        {
            title: 'trading.9',
            text: 'trading.10',
            img: sw2
        },
        {
            title: 'trading.11',
            text: 'trading.12',
            img: sw5
        },
        {
            title: 'trading.13',
            text: 'trading.14',
            img: sw4
        },
    ]
      
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[40px] xl:mt-[80px] flex flex-col">
            <div className="xl:flex items-center gap-[20px] ml-auto hidden">
                <img src={btn} className="cursor-pointer" alt="" onClick={() => swiper.slidePrev()}/>
                <img src={btn} className="rotate-180 cursor-pointer" alt="" onClick={() => swiper.slideNext()}/>
            </div>
            <div>
                <Fade left><img src={sw} className="absolute top-[-80px] left-[-160px] md:block hidden" alt="" /></Fade>
                <Swiper
                    className="mt-[20px]"
                    spaceBetween={20}
                    slidesPerView={1}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    breakpoints={{
                        1: {
                            slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 2,
                        },
                        1280: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {data.map((text, index) => (
                        <SwiperSlide key={index}>
                            <div className="bg-[#FFFFFF] h-[280px] rounded-[28px] border border-[#0038FF] relative">
                                <p className="mt-[20px] xl:mt-[40px] text-center bebas text-[16px] xl:text-[20px]">{t(text.title)}</p>
                                <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] mt-[20px] mx-[20px] xl:mx-[30px]">{t(text.text)}</p>
                                <img src={text.img} alt="" className="absolute bottom-[20px] right-[20px]"/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="flex items-center gap-[20px] ml-auto xl:hidden mt-[20px]">
                <img src={btn} className="cursor-pointer" alt="" onClick={() => swiper.slidePrev()}/>
                <img src={btn} className="rotate-180 cursor-pointer" alt="" onClick={() => swiper.slideNext()}/>
            </div>
        </div>
    );
}

export default Sw;