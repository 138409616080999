import React from "react";

import { useTranslation } from "react-i18next";

import circle from '../img/Home/circle.png'

function Button ( { className } ) {

    const { t } = useTranslation();
      
    return(
        <div className={className}>
            <a href="https://user.nine-ventures.org/">
                <div className="rounded-[80px] pl-[20px] p-[5px] flex cursor-pointer items-center gap-[10px] bg-[#0038FF]">
                    <p className="text-white helvetica text-[16px]">{t('home.13')}</p>
                    <img src={circle} alt="" />
                </div>
            </a>
        </div>
    );
}

export default Button;