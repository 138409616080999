import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import why from '../../img/Home/why.png'

import sw1 from '../../img/Home/sw1.png'
import sw2 from '../../img/Home/sw2.png'
import sw3 from '../../img/Home/sw3.png'

import btn from '../../img/Home/arrow.png'

import Fade from 'react-reveal/Fade'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const data = [
    {
        title: 'home.7',
        text: 'home.8',
        img: sw1
    },
    {
        title: 'home.9',
        text: 'home.10',
        img: sw2
    },
    {
        title: 'home.11',
        text: 'home.12',
        img: sw3
    },
]

function WhyChoose () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});
      
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]">
            <p className="bebas text-[24px] xl:text-[48px] leading-[1]">{t('home.6')}</p>
            <p className="ml-auto helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] mt-[20px] xl:hidden block">{t('home.14')}</p>
            <div>
                <Swiper
                    className="mt-[20px] xl:mt-[45px]"
                    spaceBetween={20}
                    slidesPerView={1}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    breakpoints={{
                        1: {
                            slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 2,
                        },
                        1280: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {data.map((text, index) => (
                        <SwiperSlide key={index}>
                            <div className="bg-[#FFFFFF] h-[380px] rounded-[28px] border border-[#0038FF] relative">
                                <p className="mt-[20px] xl:mt-[40px] text-center bebas text-[16px] xl:text-[20px]">{t(text.title)}</p>
                                <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] mt-[20px] mx-[20px] xl:mx-[30px]">{t(text.text)}</p>
                                <img src={text.img} alt="" className="absolute bottom-[20px] right-[20px]"/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <Fade left><img src={why} className="absolute left-0 bottom-0 hidden xl:block" alt="" /></Fade>
            <div className="flex flex-col z-25 relative">
                <div className="flex items-center gap-[20px] xl:hidden mt-[20px] ml-auto">
                    <img src={btn} className="cursor-pointer" alt="" onClick={() => swiper.slidePrev()}/>
                    <img src={btn} className="rotate-180 cursor-pointer" alt="" onClick={() => swiper.slideNext()}/>
                </div>
                <p className="ml-auto helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] xl:mt-[45px] hidden xl:block">{t('home.14')}</p>
                <Button className="ml-auto mt-[20px] hidden xl:block"/>
            </div>
        </div>
    );
}

export default WhyChoose;