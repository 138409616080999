import React from "react";

import { useTranslation } from "react-i18next";

import Fade from 'react-reveal/Fade'

import join from '../../img/Home/join.png'
import join2 from '../../img/Home/join2.svg'

import circle from '../../img/Home/circle.png'

function Join () {

    const { t } = useTranslation();

    return(
        <div className="relative max-w-[1280px] xl:mx-auto xl:h-[400px] pb-[20px] mx-[20px] mt-[80px] xl:mt-[160px] rounded-[40px] bg-[#0038FF] border border-[#0038FF]">
            <p className="bebas mt-[40px] xl:mt-[80px] text-[32px] xl:text-[64px] text-center xl:text-left leading-[1.2] xl:hidden block text-white">{t('home.42')}</p>
            <p className="bebas text-[24px] xl:text-[48px] text-center xl:text-left leading-[1] xl:hidden block text-white">{t('home.43')}</p>
            <div className="flex flex-col px-[20px] md:px-[40px] xl:px-[65px]">
                <div className="flex items-end gap-[30px]">
                    <img src={join2} alt="" />
                    <div className="flex flex-col">
                        <p className="bebas text-[16px] xl:text-[20px] text-white">{t('home.44')}</p>
                        <p className="text-[14px] xl:text-[16px] helvetica text-white">{t('home.45')}</p>
                    </div>
                </div>
                <div className="flex items-end gap-[30px]">
                    <img src={join2} alt="" />
                    <div className="flex flex-col">
                        <p className="bebas text-[16px] xl:text-[20px] text-white">{t('home.46')}</p>
                        <p className="text-[14px] xl:text-[16px] helvetica text-white">{t('home.47')}</p>
                    </div>
                </div>
                <div className="flex items-end gap-[30px] pb-[20px] xl:pb-0">
                    <img src={join2} alt="" />
                    <div className="flex flex-col">
                        <p className="bebas text-[16px] xl:text-[20px] text-white">{t('home.48')}</p>
                        <p className="text-[14px] xl:text-[16px] helvetica text-white">{t('home.49')}</p>
                    </div>
                </div>
                <a href="https://user.nine-ventures.org/" className="mr-auto mt-[20px] xl:mt-[40px] hidden xl:block">
                    <div className="rounded-[80px] pl-[20px] p-[5px] flex cursor-pointer items-center gap-[10px] bg-black">
                        <p className="text-white helvetica text-[16px]">{t('home.13')}</p>
                        <img src={circle} alt="" />
                    </div>
                </a>
            </div>    
            <div className="bg-white w-[575px] h-[100%] rounded-l-[150px] rounded-r-[40px] absolute right-0 top-0 xl:flex flex-col items-center hidden">
                <p className="bebas mt-[40px] xl:mt-[80px] text-[20px] xl:text-[40px] text-center xl:text-left leading-[1.2]">{t('home.42')}</p>
                <p className="bebas text-[16px] xl:text-[32px] text-center xl:text-left leading-[1]">{t('home.43')}</p>
                <Fade bottom><img src={join} alt="" /></Fade>
            </div>
        </div>
    );
}

export default Join;