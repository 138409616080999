import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/logo.png";
import logo_mob from "../img/logo.png";
import MobileMenu from "./MobileMenu";
import circle from '../img/Home/circle.png'

function Header() {
    const { t } = useTranslation();
    const location = useLocation();

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
        { value: 'ar', label: 'AR' },
        { value: 'de', label: 'DE' },
        { value: 'pl', label: 'PL' },
        { value: 'ro', label: 'RO' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
        // document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className="header-container bg-[#F2F2F1]">
            <div className="hidden max-w-[1280px] h-[60px] justify-between mx-auto xl:flex items-center">
                <Link to="/"><img src={logo} alt="" className="w-[40px]"/></Link>
                <Link to="/trading"><p className={`text-[16px] helvetica ${location.pathname === '/trading' ? 'text-[#0038FF]' : 'text-[#000000]'}`}>{t('header.1')}</p></Link>
                <Link to="/fees"><p className={`text-[16px] helvetica ${location.pathname === '/fees' ? 'text-[#0038FF]' : 'text-[#000000]'}`}>{t('header.2')}</p></Link>
                <Link to="/accounts"><p className={`text-[16px] helvetica ${location.pathname === '/accounts' ? 'text-[#0038FF]' : 'text-[#000000]'}`}>{t('header.3')}</p></Link>
                <Link to="/support"><p className={`text-[16px] helvetica ${location.pathname === '/support' ? 'text-[#0038FF]' : 'text-[#000000]'}`}>{t('header.4')}</p></Link>
                <Link to="/about"><p className={`text-[16px] helvetica ${location.pathname === '/about' ? 'text-[#0038FF]' : 'text-[#000000]'}`}>{t('header.5')}</p></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                />
                <a href="https://user.nine-ventures.org/">
                    <div className="rounded-[80px] px-[20px] p-[5px] flex cursor-pointer items-center gap-[10px] bg-[#0038FF]">
                        <p className="text-white helvetica text-[16px]">{t('home.13')}</p>
                    </div>
                </a>
            </div>
            <div className="xl:hidden flex mx-[20px] items-center justify-between h-[60px]">
                <Link to="/"><img src={logo_mob} alt="" className="w-[40px]"/></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                    className="ml-auto mr-[20px]"
                />
                <MobileMenu/>
            </div>
        </div>
    );
}

export default Header;
