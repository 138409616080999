import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import main from '../../img/Customer/main.png'

import Fade from 'react-reveal/Fade'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="flex flex-col">
                    <p className="bebas mt-[40px] xl:mt-[80px] text-[32px] xl:text-[64px] text-center xl:text-left leading-[1.2]">{t('customer.1')}</p>
                    <p className="bebas text-[24px] xl:text-[48px] text-center xl:text-left leading-[1]">{t('customer.2')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] mt-[45px]">{t('customer.3')}</p>
                    <p className="bebas text-[16px] xl:text-[20px] xl:max-w-[488px] mt-[20px] xl:mt-[45px]">{t('customer.4')}</p>
                    <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] mt-[15px]">{t('customer.5')}</p>
                    <Button className="mt-[35px] xl:mt-[45px] mr-auto"/>
                </div>
                <div className="mt-[20px]">
                    <Fade right><img src={main} alt="" className="mx-auto mt-[20px] xl:mt-[80px]"/></Fade>
                </div>
            </div>
        </div>
    );
}

export default Main;