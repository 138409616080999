import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import WhyChoose from "../components/Home/WhyChoose";
import Diversify from "../components/Home/Diversify";
import SmartSmall from "../components/Home/SmartSmall";
import Secure from "../components/Home/Secure";
import Transparent from "../components/Home/Transparent";
import TradeAcc from "../components/Home/TradeAcc";
import Join from "../components/Home/Join";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F2F2F1] overflow-hidden">
            <Main/>
            <WhyChoose/>
            <Diversify/>
            <SmartSmall/>
            <Secure/>
            <Transparent/>
            <TradeAcc/>
            <Join/>
        </div>
    );
}

export default Home;