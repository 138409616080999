import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import Fade from 'react-reveal/Fade'

import rec2 from '../../img/Accounts/rec2.png'
import rec1 from '../../img/Accounts/rec.png'

function Types () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    const Acc = [
        {
            name: 'fees.19',
            text1: 'accounts.4',
            text2: 'accounts.5',
            text3: 'accounts.6',
            text4: 'accounts.7',
            text5: 'accounts.8',
            text6: 'accounts.9',
        },
        {
            name: 'fees.20',
            text1: 'accounts.10',
            text2: 'accounts.11',
            text3: 'accounts.12',
            text4: 'accounts.13',
            text5: 'accounts.14',
            text6: 'accounts.15',
        },
        {
            name: 'fees.21',
            text1: 'accounts.16',
            text2: 'accounts.17',
            text3: 'accounts.18',
            text4: 'accounts.19',
            text5: 'accounts.20',
            text6: 'accounts.21',
        },
        {
            name: 'fees.22',
            text1: 'accounts.22',
            text2: 'accounts.23',
            text3: 'accounts.24',
            text4: 'accounts.25',
            text5: 'accounts.26',
            text6: 'accounts.27',
            text7: 'accounts.28',
        },
        {
            name: 'fees.23',
            text1: 'accounts.29',
            text2: 'accounts.30',
            text3: 'accounts.31',
            text4: 'accounts.32',
            text5: 'accounts.33',
            text6: 'accounts.34',
            text7: 'accounts.35',
        },
    ]
      
    return(
        <div>
            <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]">
                <div className="flex xl:max-w-[300px]">
                    <p className="bebas text-[24px] xl:text-[48px] text-center xl:text-left leading-[1.3]">{t('accounts.3')}</p>
                    <img src={rec1} alt="" className="absolute left-1/2 top-[-100px] hidden 2xl:block"/>
                    <img src={rec2} alt="" className="absolute right-[-10%] top-[-300px] hidden 2xl:block"/>
                </div>
                <div className="flex flex-col xl:flex-row justify-between mt-[40px] xl:mt-[80px]">
                    <div className="xl:w-[383px] flex flex-wrap xl:flex-col gap-[10px] mt-[20px] xl:mt-0">
                        {Acc.map((cc, index) => (
                            <div key={index} onClick={() => setActive(index)} className={`flex items-center justify-center px-[20px] flex-grow h-[53px] rounded-[80px] cursor-pointer transition-all border border-[#0038FF] ${active === index ? 'bg-[#0038FF]' : 'bg-white'}`}>
                                <p className={`bebas text-[20px] xl:text-[32px] transition-all ${active === index ? 'text-white' : 'text-black'}`}>{t(cc.name)}</p>
                            </div>
                        ))}
                    </div>
                    <div className="mt-[20px] xl:mt-0 xl:max-w-[600px]">
                        <Fade key={active}><p className="text-[30px] xl:text-[48px] bebas hidden xl:block">{t(Acc[active].name)}</p>
                        <p className="text-[14px] xl:text-[16px] helvetica leading-[1.4] mt-[20px] hidden xl:block">{t(Acc[active].text1)}</p>
                        <div className="flex items-center gap-[20px] mt-[20px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="7.57053" r="7.57639" fill="#0038FF"/>
                            </svg>
                            <p className="text-[14px] xl:text-[16px] helvetica">{t(Acc[active].text2)}</p>
                        </div>
                        <div className="flex items-center gap-[20px] mt-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="7.57053" r="7.57639" fill="#0038FF"/>
                            </svg>
                            <p className="text-[14px] xl:text-[16px] helvetica">{t(Acc[active].text3)}</p>
                        </div>
                        <div className="flex items-center gap-[20px] mt-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="7.57053" r="7.57639" fill="#0038FF"/>
                            </svg>
                            <p className="text-[14px] xl:text-[16px] helvetica">{t(Acc[active].text4)}</p>
                        </div>
                        <div className="flex items-center gap-[20px] mt-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="7.57053" r="7.57639" fill="#0038FF"/>
                            </svg>
                            <p className="text-[14px] xl:text-[16px] helvetica">{t(Acc[active].text5)}</p>
                        </div>
                        <div className="flex items-center gap-[20px] mt-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="7.57053" r="7.57639" fill="#0038FF"/>
                            </svg>
                            <p className="text-[14px] xl:text-[16px] helvetica">{t(Acc[active].text6)}</p>
                        </div>
                        {Acc[active]?.text7 ? ( <div className="flex items-center gap-[20px] mt-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="7.57053" r="7.57639" fill="#0038FF"/>
                            </svg>
                            <p className="text-[14px] xl:text-[16px] helvetica">{t(Acc[active].text7)}</p>
                        </div> ) : ( <></> )}</Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Types;