import React from "react";

import { useTranslation } from "react-i18next";

import rec from '../../img/Accounts/rec3.png'

function Belives () {

    const { t } = useTranslation();
      
    return(
        <div className="relative overflow-hidden max-w-[1280px] xl:mx-auto mx-[20px] bg-[#0038FF] rounded-[20px] xl:rounded-[30px] py-[20px] xl:py-[45px] px-[20px] xl:px-[105px] mt-[80px] xl:mt-[160px]">
           <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[547px] text-white">{t('accounts.36')}</p>
           <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[547px] text-white mt-[20px] xl:mt-[45px]">{t('accounts.37')}</p>
           <img src={rec} className="xl:absolute right-0 top-0 mx-auto mt-[30px] xl:mt-0 rounded-[20px] h-[300px] xl:h-full object-cover w-full xl:w-auto" alt="" />
        </div>
    );
}

export default Belives;