import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import Fade from 'react-reveal/Fade'

import girl from '../../img/Fees/girl.png'

function PlatformFees () {

    const { t } = useTranslation();
      
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[260px]">
            <div className="xl:h-[332px] bg-[#0038FF] rounded-[30px] relative flex items-center justify-center xl:justify-end py-[20px] xl:py-0 px-[20px] xl:px-[95px]">
                <Fade><img src={girl} alt="" className="absolute bottom-0 left-[10px] hidden xl:block"/></Fade>
                <div className="flex flex-col xl:flex-row gap-[20px] xl:gap-[140px]">
                    <div>
                        <p className="text-white bebas text-[30px] xl:text-[64px]">{t('fees.7')}</p>
                        <p className="helvetica text-[14px] xl:text-[16px] text-white xl:max-w-[419px] mt-[30px] xl:mt-[45px] leading-[1.4]">{t('fees.8')}</p>
                        <p className="helvetica text-[14px] xl:text-[16px] text-white xl:max-w-[419px] mt-[15px] leading-[1.4]">{t('fees.9')}</p>
                    </div>
                    <div className="flex flex-col justify-end">
                        <p className="bebas text-[16px] xl:text-[20px] text-white">{t('fees.10')}</p> 
                        <div className="flex items-center gap-[15px] mt-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="7.82639" r="7.57639" fill="white"/>
                            </svg>
                            <p className="bebas text-[16px] xl:text-[20px] text-white">{t('fees.11')}</p> 
                        </div>
                        <div className="flex items-center gap-[15px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="7.82639" r="7.57639" fill="white"/>
                            </svg>
                            <p className="bebas text-[16px] xl:text-[20px] text-white">{t('fees.12')}</p> 
                        </div>
                        <div className="flex items-center gap-[15px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="7.82639" r="7.57639" fill="white"/>
                            </svg>
                            <p className="bebas text-[16px] xl:text-[20px] text-white">{t('fees.13')}</p> 
                        </div>
                        <div className="flex items-center gap-[15px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="7.82639" r="7.57639" fill="white"/>
                            </svg>
                            <p className="bebas text-[16px] xl:text-[20px] text-white">{t('fees.14')}</p> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[45px]">
                <div className="flex flex-col gap-[15px]">
                    <div className="flex items-center gap-[30px]">
                        <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px]">{t('fees.15')}</p>
                        <svg className="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="12" height="91" viewBox="0 0 12 91" fill="none">
                            <line y1="-0.952525" x2="80.9126" y2="-0.952525" transform="matrix(1.19249e-08 1 1 -1.19249e-08 6.94202 0.000976562)" stroke="url(#paint0_linear_75_696)" stroke-width="1.90505"/>
                            <circle cx="5.94956" cy="5.94956" r="4.99704" transform="matrix(1 0 0 -1 0 90.9688)" fill="#0038FF" stroke="url(#paint1_linear_75_696)" stroke-width="1.90505"/>
                            <defs>
                                <linearGradient id="paint0_linear_75_696" x1="80.9126" y1="-1.85653e-06" x2="0" y2="4.88109e-06" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0038FF"/>
                                <stop offset="1" stop-color="#0038FF" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_75_696" x1="11.8991" y1="-2.20911e-05" x2="0" y2="-2.20789e-05" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0038FF"/>
                                <stop offset="1" stop-color="#0038FF" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="flex items-center gap-[30px]">
                        <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px]">{t('fees.16')}</p>
                        <svg className="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="12" height="91" viewBox="0 0 12 91" fill="none">
                            <line y1="-0.952525" x2="80.9126" y2="-0.952525" transform="matrix(1.19249e-08 1 1 -1.19249e-08 6.94202 0.000976562)" stroke="url(#paint0_linear_75_696)" stroke-width="1.90505"/>
                            <circle cx="5.94956" cy="5.94956" r="4.99704" transform="matrix(1 0 0 -1 0 90.9688)" fill="#0038FF" stroke="url(#paint1_linear_75_696)" stroke-width="1.90505"/>
                            <defs>
                                <linearGradient id="paint0_linear_75_696" x1="80.9126" y1="-1.85653e-06" x2="0" y2="4.88109e-06" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0038FF"/>
                                <stop offset="1" stop-color="#0038FF" stop-opacity="0"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_75_696" x1="11.8991" y1="-2.20911e-05" x2="0" y2="-2.20789e-05" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0038FF"/>
                                <stop offset="1" stop-color="#0038FF" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className="flex flex-col mt-[15px] xl:mt-0 justify-between">
                    <div>
                        <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px]">{t('fees.17')}</p>
                        <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] mt-[15px]">{t('fees.18')}</p>
                    </div>
                    <Button className="mt-[30px] xl:mt-[20px] mr-auto"/>
                </div>
            </div>
        </div>
    );
}

export default PlatformFees;