import React, { useEffect } from "react";

import Main from '../components/Customer/Main'
import Sw from "../components/Customer/Sw";
import Avaliable from "../components/Customer/Avaliable";
import Faq from "../components/Customer/Faq";
import Join from "../components/Home/Join";


function Customer () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F2F2F1] overflow-hidden">
            <Main/>
            <Sw/>
            <Avaliable/>
            <Faq/>
            <Join/>
        </div>
    );
}

export default Customer;