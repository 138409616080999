import React, { useEffect } from "react";

import Main from '../components/Fees/Main'
import PlatformFees from "../components/Fees/PlatformFees";
import AccountBased from "../components/Fees/AccountBased";
import Please from "../components/Fees/Please";
import Bonuses from "../components/Fees/Bonuses";
import Exceptional from "../components/Fees/Exceptional";
import Join from "../components/Home/Join";

function Fees () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F2F2F1] overflow-hidden">
            <Main/>
            <PlatformFees/>
            <AccountBased/>
            <Please/>
            <Bonuses/>
            <Exceptional/>
            <Join/>
        </div>
    );
}

export default Fees;