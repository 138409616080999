import React from 'react';

import { useTranslation, Trans } from "react-i18next";

import logo from '../img/logo_footer.png'

const { hostname } = document.location;

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#F2F2F1] z-10 relative pt-[80px] xl:pt-[160px]'>
      <div className='max-w-[1280px] mx-[20px] bg-black xl:mx-auto rounded-[30px] px-[20px] py-[60px]'>
        <div className='flex flex-col xl:flex-row items-start justify-between gap-[10px] xl:gap-0'>
          <img src={logo} alt="" className='w-[50px]'/>
          <div className='flex flex-col gap-[10px]'>
            <p className='helvetica text-[16px] text-white'>{t('footer.1')} +442046009735</p>
            <p className='helvetica text-[16px] text-white'>{t('footer.2')} 3 SHENTON WAY, #11-10,<br/> SHENTON HOUSE, SINGAPORE</p>   
            <p className='helvetica text-[16px] text-white'>{t('footer.3')} support@{hostname}</p>
          </div>
          <div className='flex flex-col gap-[10px]'>
            <a href="/docs/client.pdf"><p className='helvetica text-[16px] text-white'>{t('footer.4')}</p></a>
            <a href="/docs/privacy.pdf"><p className='helvetica text-[16px] text-white'>{t('footer.5')}</p></a>
            <a href="/docs/conflict.pdf"><p className='helvetica text-[16px] text-white'>{t('footer.6')}</p></a>
          </div>
          <div className='xl:w-[643px] flex flex-col gap-[10px] mt-[10px] xl:mt-0 xl:gap-[20px]'>
            <p className='helvetica text-[16px] text-white'>{t('footer.7')}</p>
            <p className='helvetica text-[14px] text-white'><Trans>{t('footer.8')}</Trans></p>
            <p className='helvetica text-[18px] text-white ml-auto mt-[10px]'>Nine Ventures © 2024. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
      
  );
}

export default Footer;