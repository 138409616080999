import React from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import Fade from 'react-reveal/Fade'

import img from '../../img/Home/div.png'

function Diversify () {

    const { t } = useTranslation();
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]">    
            <p className="bebas text-[24px] xl:text-[48px] text-center xl:text-left">{t('home.15')}</p>  
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[45px]">
                <div className="flex flex-col">
                    <Fade left><img src={img} alt="" className="mx-auto"/></Fade>
                    <Button className="hidden xl:block mt-[45px] mr-auto"/>
                </div>
                <div className="xl:w-[488px] mt-[20px] xl:mt-0">
                    <p className="helvetica text-[14px] xl:text-[16px]">{t('home.16')}</p>
                    <div className="flex flex-wrap mt-[20px] xl:mt-[45px] gap-x-[30px] gap-y-[20px]">
                        <div className="flex items-center gap-[10px]">
                            <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="8.08811" r="7.07639" fill="#0038FF" stroke="#0038FF"/>
                            </svg>
                            <p className="helvetica text-[14px] xl:text-[16px]">{t('home.17')}</p>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="8.08811" r="7.07639" fill="#0038FF" stroke="#0038FF"/>
                            </svg>
                            <p className="helvetica text-[14px] xl:text-[16px]">{t('home.18')}</p>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="8.08811" r="7.07639" fill="#0038FF" stroke="#0038FF"/>
                            </svg>
                            <p className="helvetica text-[14px] xl:text-[16px]">{t('home.19')}</p>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="8.08811" r="7.07639" fill="#0038FF" stroke="#0038FF"/>
                            </svg>
                            <p className="helvetica text-[14px] xl:text-[16px]">{t('home.20')}</p>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="8.08811" r="7.07639" fill="#0038FF" stroke="#0038FF"/>
                            </svg>
                            <p className="helvetica text-[14px] xl:text-[16px]">{t('home.21')}</p>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="8.08811" r="7.07639" fill="#0038FF" stroke="#0038FF"/>
                            </svg>
                            <p className="helvetica text-[14px] xl:text-[16px]">{t('home.22')}</p>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="8.08811" r="7.07639" fill="#0038FF" stroke="#0038FF"/>
                            </svg>
                            <p className="helvetica text-[14px] xl:text-[16px]">{t('home.23')}</p>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <circle cx="7.57639" cy="8.08811" r="7.07639" fill="#0038FF" stroke="#0038FF"/>
                            </svg>
                            <p className="helvetica text-[14px] xl:text-[16px]">{t('home.24')}</p>
                        </div>
                    </div>
                    <p className="helvetica text-[14px] xl:text-[16px] mt-[20px] xl:mt-[45px]">{t('home.25')}</p>
                </div>
            </div>
        </div>
    );
}

export default Diversify;