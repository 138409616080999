import React, { useEffect, useState } from "react";
import $ from 'jquery';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { images } from "./imagesToLoad";

import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import Trading from "./pages/Trading";
import Fees from "./pages/Fees";
import Account from "./pages/Account";
import Customer from "./pages/Customer";
import About from "./pages/About";

function App() {
  
  const [ isLoading, setIsLoading ] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]); 

  const [pass, setPass] = useState('');
  const [access, setAccess] = useState(false);

  const sendPass = () => {
    fetch('https://whoareyou.devindex.net/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pass }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.result === true && data.token) {
          const expirationTime = new Date().getTime() + 60 * 60 * 1000;
          localStorage.setItem('authToken', JSON.stringify({ token: data.token, expires: expirationTime }));
          setAccess(true);
        } else {
          alert('Неверный пароль');
        }
      });
  };

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('authToken'));
    if (authData) {
      const currentTime = new Date().getTime();
      if (authData.expires > currentTime) {
        setAccess(true);
      } else {
        localStorage.removeItem('authToken');
      }
    }
  }, []);
  
  return (
    <>
      {!access ?
        <div style={{ padding: '20px', display: 'flex' }}>
          <input
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            style={{ padding: '0 10px', border: '1px solid #000' }}
          />
          <div
            onClick={sendPass}
            style={{ padding: '10px 20px', color: '#fff', background: '#000', cursor: 'pointer' }}
          >
            Send
          </div>
        </div>
        :
        <Router>
          <Header/>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/trading" element={<Trading />} />
              <Route path="/fees" element={<Fees />} />
              <Route path="/accounts" element={<Account />} />
              <Route path="/support" element={<Customer />} />
              <Route path="/about" element={<About />} />
            </Routes>
          <Footer/>
        </Router>
        }
      </>
    
  );
}

export default App;