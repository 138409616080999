import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from '../Button'

import Fade from 'react-reveal/Fade'

import tools from '../../img/Accounts/rec4.png'

import circle from '../../img/Home/circle.png'

function WhatType () {

    const { t } = useTranslation();

    const faqData = [
        {
            question: 'accounts.41',
            answer: 'accounts.42'
        },
        {
            question: 'accounts.43',
            answer: 'accounts.44'
        },
        {
            question: 'accounts.45',
            answer: 'accounts.46'
        },
        {
            question: 'accounts.47',
            answer: 'accounts.48'
        },
        {
            question: 'accounts.49',
            answer: 'accounts.50'
        },
    ]

    const [openIndex, setOpenIndex] = useState(1);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
      
    return(
        <div className="relative max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between mt-[80px] xl:mt-[160px]">
            <div className="flex flex-col">
                <p className="bebas text-[24px] xl:text-[48px] text-center xl:text-left leading-[1]">{t('accounts.38')}</p>
                <Fade left><img src={tools} alt="" className="mt-[35px] xl:mt-[45px]"/></Fade>
                <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] mt-[35px] xl:mt-[45px]">{t('accounts.39')}</p>
                <Button className="mt-[35px] xl:mt-[45px] mr-auto"/>
            </div>
            <div className="xl:w-[593px] flex flex-col">
                <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px]">{t('accounts.40')}</p>
                {faqData.map((faq, index) => (
                    <div key={index}>
                        <div className={`flex justify-between mt-[20px] transition-all duration-300 rounded-[30px] border border-[#0038FF] ${openIndex === index ? 'h-auto bg-[#0038FF]' : 'bg-[#F3F2F2] h-[74px]'}`}>
                            <div className='mx-[50px] flex flex-col justify-center'>
                                <p className={`bebas text-[16px] xl:text-[20px] ${openIndex === index ? 'text-[#FFF] mt-[15px]' : 'text-[#000]'} max-w-[200px] xl:max-w-[1280px]`}>{t(faq.question)}</p>
                                {openIndex === index && (
                                    <p className='text-[#FFF] roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[593px]'>{t(faq.answer)}</p>
                                )}
                            </div>
                            <div className={`mx-[25px] w-[30px] h-[30px] ${openIndex === index ? 'bg-white' : 'bg-[#0038FF]'} flex-shrink-0 rounded-[80px] flex cursor-pointer items-center justify-center mt-[20px]`} onClick={() => toggleText(index)}>
                                <svg className={`transition-all ${openIndex === index ? '' : '-rotate-90'}`} xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                                    <path d="M8.17566 9.58691L16.9501 16.6852" stroke={openIndex === index ? 'black' : 'white'} stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9.69702 16.6787L16.9495 16.6859L15.4277 9.59485" stroke={openIndex === index ? 'black' : 'white'} stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WhatType;