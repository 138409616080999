import React from "react";

import { useTranslation } from "react-i18next";

import img1 from '../../img/Fees/i1.png'
import img2 from '../../img/Fees/i2.png'

import Button from '../Button'

function Please () {

    const { t } = useTranslation();
 
    return(
        <div className="relative max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <div>
                    <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px]">{t('fees.41')}</p>
                    <img src={img1} className="xl:mt-[45px] mt-[20px]" alt="" />
                    <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] xl:mt-[45px] mt-[20px]">{t('fees.42')}</p>
                </div>
                <div className="flex flex-col">
                    <img src={img2} className="xl:mt-[0px] mt-[20px]" alt="" />
                    <p className="helvetica text-[14px] xl:text-[16px] xl:max-w-[488px] xl:mt-[45px] mt-[20px]">{t('fees.42')}</p>
                    <Button className="mt-[20px] xl:mt-[45px] mr-auto"/>
                </div>
            </div>
        </div>
    );
}

export default Please;